const helper = {
    methods: {
        changeStep(stepNumber) {
            this.$store.commit("setCurrentSetupStep", stepNumber);
            this.$emit("change-step", stepNumber);
        },
        async showAndLogError(response) {
            this.$logger.loggable(response).log();
            await this.$feedback.showAutoFailed(response);
        },
        isEmailStepValid() {
            if (this.step && this.step.valid) {
                if (this.form && this.form.email) {
                    return true;
                }
            }
            return false;
        }
    }
}

export default helper;