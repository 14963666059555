<template>
  <v-container v-if="enableSetup" class="setup-container">
    <div class="language-container">
      <v-menu
          transition="slide-y-transition"
          bottom>
        <template v-slot:activator="{ on, attrs }">
          <div class="language-container__flex" v-bind="attrs" v-on="on">
              <span class="language-text">
                {{ lang }}
              </span>
            <img class="drop-down-arrow" src="@/assets/image/landing/arrow-ios-downward.svg" alt="img">
          </div>
        </template>
        <v-list>
          <v-list-item
              v-for="(item, i) in languages"
              :key="i">
            <v-list-item-title class="drop-down-item" @click="changeLang(item)">
              {{ item }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <component :is="loadComponent" :action="action" :data="data"
               v-on:change-fragment="changeFragment">
    </component>
  </v-container>
</template>

<script>
import CommonPage from "@/components/setup/ui/views/CommonPage";
import ComingSoon from "@/components/setup/ui/views/ComingSoon";
import Setup from "@/components/setup/ui/views/Setup";
import trackGtagEvent from "@/util/TrackGtagEvent";
import helper from "@/util/OnboardingHelper";

export default {
  name: 'SetupLayout',
  mixins: [trackGtagEvent, helper],
  components: {CommonPage, ComingSoon, Setup},
  beforeMount() {
    let isMailVerify = this.$route.query['email-hash'];
    if (isMailVerify) {
      this.confirmEmail(isMailVerify);
    }
  },

  data() {
    return {
      languages: ['eng', 'বাংলা'],
      lang: this.$i18n.locale || 'বাংলা',
      enableSetup: true,
      fragment: 'Setup',
      action: '',
      data: null,
      drawer: null,
      menus: [
        {
          name: 'menu.home',
          icon: 'mdi-home',
          link: '/',
          dropdown: false,
          active: true
        }
      ]
    }
  },
  methods: {
    changeLang(language) {
      this.lang = language;
      this.$i18n.locale = language;
      localStorage.setItem('lang', language);
    },
    changeFragment(fragment, action, data) {
      this.fragment = fragment;
      this.action = action;
      this.data = data;
    },

    async confirmEmail(hash) {
      const verifyEmailPath = 'auto-onboard/verify-email';
      this.toggleSetup(false);
      this.toggleLoading(true);
      try {
        await this.$cppClient.post(verifyEmailPath, {verificationHash: hash});
        this.trackSuccess();
        this.changeFragment(CommonPage, "EmailSuccess", {});
      } catch ({response}) {
        await this.showAndLogError(response);
        this.trackError(response);
      }
      this.toggleSetup(true);
      this.toggleLoading(false)
    },

    toggleSetup(isEnableSetup) {
      this.enableSetup = !!isEnableSetup;
    },

    toggleLoading(isLoading) {
      if (isLoading) {
        this.$feedback.showLoading();
      } else {
        this.$feedback.hideLoading();
      }
    },
    trackSuccess() {
      this.trackSignUpPageEvent("successfully verified from the email link");
    },
    trackError(response) {
      this.trackSignUpPageErrorEvent("failed to verify from the email link",response);
    }
  },

  computed: {
    loadComponent() {
      return this.fragment;
    }
  },
  mounted() {
    this.trackSignUpPageEvent("landed on sign up page");
    this.$gtag.screenview({
      app_name: 'bkash Business Dashboard',
      screen_name: 'Sign Up Page',
    })
  }
}
</script>

<style scoped>
html * {
  font-family: 'Hind Siliguri', Roboto, serif !important;
  letter-spacing: 0 !important;
}

.overflow-hidden {
  overflow: hidden;
}

.main_layout .content_section {
  min-height: calc(100vh - 132px);
  display: flex;
  align-items: stretch;
}

.step_card .v-text-field__details {
  min-height: 16px;
}

.step_card .v-messages__message {
  line-height: 16px;
}

.drop-down-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.drop-down-arrow {
  height: 27px;
  width: 29px;
  cursor: pointer;
}

.drop-down-item {
  cursor: pointer;
  text-transform: capitalize !important;
}

.language-text {
  text-transform: capitalize !important;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  margin-top: 5px;
}

.setup-container {
  width: 100% !important;
  max-width: 100%;
  height: 100vh;
  justify-content: center;
  padding: 0;
}

.language-container {
  position: absolute;
  top: 10px;
  right: 30px;
  width: 30px;
  z-index: 5
}

.language-container__flex {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 768px) {
  /* .setup_main {
    height: auto !important;
  } */
  .main_layout .content_section {
    height: calc(100vh - 70px) !important;
  }

  .main_layout .content_section {
    /*height: calc(100vh - 132px);*/
    overflow-y: auto;
    overflow-x: hidden;
  }

  .v-list-item__content {
    margin-left: 5px;
    color: #707070 !important;
  }

  .v-list-item__icon {
    color: #707070 !important;
  }
}
</style>