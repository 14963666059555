const trackGtagEvent = {
    methods: {
        trackSignUpPageEvent(action) {
            this.$gtag.event(action, {
                'event_category': "Sign Up Page"
            });
        },
        trackSignUpPageErrorEvent(action, response) {
            this.$gtag.event(action, {
                'event_category': "Sign Up Page Error",
                'event_label': JSON.stringify(response)
            });
        },
        trackOnboardingErrorEvent(action, response) {
            this.$gtag.event(action, {
                'event_category': "onboarding",
                'event_label': JSON.stringify(response)
            });
        },
        trackEntryEvent(action) {
            this.$gtag.event(action, {
                'event_category': "entry"
            });
        },
    }
}

export default trackGtagEvent;