<template>
  <v-container class="container-setup">
    <div class="left_part">
      <v-img class="setup_left_banner" :src="bannerImg"/>
    </div>

    <div class="right_part">
      <v-sheet class="container-right">
        <img src="../../../../assets/image/bKash-logo.svg" class="bkash-icon"><br>
        <div class="container-right__title">
          <span class="title_style">
            {{ $t('setup.title') }}
          </span>
        </div>
        <br>
        <div class="container-right__subtitle">
          <span class="sub-title_style">{{ $t('setup.title_slot') }}</span>
        </div>

        <div class="bottom-space">
          <v-container class="prerequisite-content-block">
            <v-row class="container-right__wallet">
              <img src="../../../../assets/image/check-white.svg" class="container-right__wallet--image" alt="white-check">
              <span class="prerequisite-content">
                {{$t('setup.prerequisite.wallet')}}
              </span>
            </v-row>
            <br>
            <v-row class="container-right__email">
              <img src="../../../../assets/image/check-white.svg" class="container-right__email--image" alt="white-check">
              <span class="prerequisite-content">
                {{$t('setup.prerequisite.email')}}
              </span>
            </v-row>
          </v-container>
        </div>


      <v-stepper v-model="curr" color="green" light>
        <v-stepper-content v-for="(step,n) in steps" :step="n+1" :key="n">
          <v-card color="" class="mb-12 step_card">
            <v-card-text  style="justify-content: center; display: inline-flex;">
              <v-form :ref="'stepForm'" v-model="step.valid" lazy-validation @submit.prevent>
                <component v-if="curStep === n" v-bind:is="step.component"
                           :rules="step.rules" :step="step" :stepNumber="n" :form="form"
                           :ismobile="isMobile" :dialog="dialog"
                           v-on:change-step="validate">

                </component>
              </v-form>
            </v-card-text>
          </v-card>
        </v-stepper-content>
      </v-stepper>
      </v-sheet>
    </div>


    <Modal :dialog="dialog">
      <template v-slot:header v-if="dialog.header">
        {{ dialog.header ? dialog.header : 'ভুল মার্চেন্ট একাউন্ট নাম্বার !' }}
      </template>
      <template v-slot:image v-if="dialog.image">
        <v-img max-width="100%" :src="require('@/assets/image/' + dialog.image)" alt="Error"/>
      </template>
      <template v-slot:content v-if="dialog.text">
        <div v-html="dialog.text">hi</div>
      </template>
    </Modal>

  </v-container>
</template>

<script>
import {PHONE_NUMBER_RULE} from "cpp-js-util/CppUtil";
import SetupWalletEntry from "@/components/setup/ui/views/steps/SetupWalletEntry";
import SetupOtpEntryPage from "@/components/setup/ui/views/steps/SetupOtpEntryPage";
import SetupEmailEntry from "@/components/setup/ui/views/steps/SetupEmailEntry";
import SetupConfirmEmailEntry from "@/components/setup/ui/views/steps/SetupConfirmEmailEntry";
import MobileDetection from "@/util/MobileDetection";
import Modal from "@/components/common/Modal";
import {mapGetters} from "vuex";

export default {
  components: {Modal, SetupWalletEntry},
  mixins: [MobileDetection],
  data() {
    return {
      bannerImg: require('@/assets/image/setup_left.png'),
      dialog: {
        enable: false,
        header: null,
        image: null,
        text: null
      },
      isValid: true,
      currentStep: 1,
      lastStep: 4,
      curStep: 0,
      valid: false,
      stepForm: [],
      curr: null,
      step: 'wallet',
      steps: [
        {
          name: "wallet", rules: {
            walletRules: [
              v => !!v || this.$i18n.t('setup.wallet_entry_form.wallet_mandatory'),
              v => (v && v.length === 11) || this.$i18n.t('setup.wallet_entry_form.wallet_length'),
              v => PHONE_NUMBER_RULE.test(v) || this.$i18n.t('setup.wallet_entry_form.wallet_valid'),
            ],
            tosRules: [
              v => !!v || this.$i18n.t('setup.wallet_entry_form.must_agree')
            ]
          }, valid: false, component: SetupWalletEntry
        },
        {
          name: "otp", rules: {
            otpRules: [
              v => !!v || this.$i18n.t('setup.otp_entry_form.otp_blank'),
              v => (v && v.length === 6) || this.$i18n.t('setup.otp_entry_form.otp_length')
            ],
          }, valid: true, component: SetupOtpEntryPage
        },
        {
          name: "email",
          rules: {
            emailRules: [
              v => (v && v.length >= 4) || this.$i18n.t('setup.email_length'),
              v => this.emailRegex.test(v) || this.$i18n.t('setup.email_valid'),
            ]
          },
          valid: true,
          component: SetupEmailEntry
        },
        {
          name: "confirmEmail",
          rules: {
            emailRules: [
              v => (v && v.length >= 4) || this.$i18n.t('setup.email_length'),
              v => this.emailRegex.test(v) || this.$i18n.t('setup.email_valid'),
              v => (v === this.$store.getters.getConfirmEmail) || this.$i18n.t('setup.email_mismatch'),
            ]
          },
          valid: true,
          component: SetupConfirmEmailEntry
        },
        {name: "Complete"}
      ],
      form: {
        wallet: '',
        tos: false,
        sessionId: '',
        otp: '',
        emailSessionId: '',
        email: '',
        confirmEmail: '',
      }
    }
  },
  computed: {
    ...mapGetters({
      activeStep: 'getCurrentSetupStep'
    }),
    emailRegex() {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    },
  },
  methods: {
    stepComplete(step) {
      return this.curr > step
    },
    stepStatus(step) {
      return this.curr > step ? 'green' : 'blue'
    },
    validate(n) {
      this.steps[n].valid = false;
      let validation = this.$refs.stepForm[n].validate()
      if (validation) {
        this.steps[n].valid = true
        // continue to next
        this.curr = n + 2
        this.curStep++;
        if (this.steps[n + 1].name === 'Complete') {
          this.$emit('change-fragment', 'CommonPage', 'EmailVerify', {form: this.form});
        }
      }
    },
    done() {
      this.curr = 5
    }
  }
}
</script>

<style scoped>
.setup_left_banner {
  max-width: 855px !important;
  margin-bottom: 58px;
  margin-top: 25px;
}

.title_style {
  font-size: 42px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
}

.sub-title_style {
  font-size: 36px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #95989a;
}

.prerequisite-content {
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
}

.prerequisite-title {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffcb15;
}

.step_card:not(.v-sheet--outlined) {
  box-shadow: none;
}

.steps-indicator {
  margin-bottom: 60px;
}
.steps-indicator ul {
  padding-left: 0;
}

.theme--light.v-card {
  color: #707070;
}

.v-stepper {
  background: none;
  box-shadow: none;
}

.v-stepper__content {
  padding: 0;
}

.theme--light.v-card {
  background: none;
}

.step_card .v-input__slot {
  background-color: #fff !important;
}

.left_part {
  padding: 132px 52px;
  flex: 1;
  width: 50%;
  background: rgba(149, 152, 154, 0.25)
}

.right_part {
  flex: 2;
  width: 50%;
}

.prerequisite-content-block {
  width: 380px;
  padding: 0px;
}

.break-line {
  display: none;
}

.container-right__title {
  display: inline-flex;
  margin-bottom: 30px;
}

.container-right__subtitle {
  display: inline-flex;
  margin-bottom: 30px;
}

.bottom-space{
  margin-bottom: 38px
}

.container-right__wallet {
  text-align: left;
  margin-bottom: -20px;
  align-items: center
}

.container-right__wallet--image {
  background-color: #e2136e;
  margin-right: 5px;
  border-radius: 3px;
  height: 19px;
}

.container-right__email {
  text-align: left;
  align-items: center;
}

.container-right__email--image {
  background-color: #e2136e;
  margin-right: 5px;
  border-radius: 3px;
  height: 19px;
}

/*
  For Mobile View
*/
@media only screen and (max-width: 425px) {
  .title_style {
    font-size: 40px;
  }

  .left_part {
    display: none;
  }

  .sub-title_style {
    font-size: 32px;
    line-height: 1;
  }

  .break-line {
    display: inherit;
  }

  .bkash-icon {
    width: 140px;
    height: 64px;
  }

  .prerequisite-content {
    font-size: 16px;
  }

  .prerequisite-content-block {
    width: 302px;
  }
}

@media screen and (max-width: 768px) {
  .setup_left_banner {
    max-width: 280px !important;
    padding-left: 21px;
    margin-bottom: 0;
    margin-top: 0;
  }

  .steps-indicator {
    margin-bottom: 17px;
  }

  .steps-indicator ul li {
    font-size: 12px;
    margin-bottom: 10px;
    padding-left: 19px;
  }

  .wallet_input p {
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 18px;
    color: #707070;
  }

  .wallet_input .v-input {
    display: block;
    max-width: 100%;
  }

  .step_card .v-card__text {
    padding: 0;
  }

  .step_card p {
    font-size: 13px;
  }
}
</style>

<style>
a {
  color: #e2136e;
  text-decoration: none;
}

.v-input__slot {
  background: transparent !important;
}

.step_card .v-text-field label {
  left: 0;
}

.error--text {
  color: #e2136e !important;
  border-color: #e2136e !important;
}

.step_card_p {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #95989a;
  margin-bottom: 35px !important;
  max-width: 400px;
}

.step_card .v-text-field .v-input__slot {
  background-color: #fff !important;
}


@media screen and (max-width: 768px) {
  .step_card_p {
    margin-bottom: 15px;
    font-size: 20px;
    max-width: 330px;
  }

  .step_card .v-input--selection-controls {
    margin-top: 5px;
  }

  .tos-check .v-label {
    font-size: 12px !important;
    margin: 0;
    display: block;
  }

  .step_card .v-input {
    max-width: 100% !important;
  }

  .setup-btn.v-btn {
    max-width: 100% !important;
    width: 98% !important;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.47) !important;
  }


  .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0;
  }
  .tos-check .v-input__slot {
    margin-bottom: 0;
  }

}
</style>
