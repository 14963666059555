<template>
  <v-layout>
    <v-flex>

      <p class="step_card_p">{{ $t('setup.wallet_entry_form.insert_wallet') }}</p>
      <v-text-field
          v-model="form.wallet"
          placeholder="01XXXXXXXXX"
          outlined
          type="number"
          v-on:input="trimInput($event)"
          oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          maxlength="11"
          dense
          class="inputNumber inputNumber-container"
          :rules="rules.walletRules"
          required
          height="56px"
          id="walletIn"
      ></v-text-field>

      <v-checkbox class="tos-check bottom-space" color="#ffcb15" :ripple="false" v-model="form.tos"
                  :rules="rules.tosRules"
                  :false-value="false" :true-value="true">
        <span slot="label" class="terms-and-conditions">
          <i18n path="setup.wallet_entry_form.terms_conditions">
            <template v-slot:conditions>
              <a class="wallet-font" @click.stop :href="getTNCLink"
                 target="_blank">{{ $t('setup.wallet_entry_form.terms_conditions_slot') }}</a>
            </template>
          </i18n>
        </span>
      </v-checkbox>

      <div class="recaptcha-v2-container" ref="recaptchaV2Container"></div>

      <v-btn class="setup-btn clr-default" color="primary" v-on:click="next" :loading="walletLoading"
             :disabled="checkWalletValidEntry">{{ $t('setup.proceed_btn') }}
      </v-btn>
      <div>
        <span slot="label" class="alert-container">
          {{ $t('setup.wallet_entry_form.have_account') }} <a @click="$router.push({name: 'sign-in'});"
                                                              style="color: #ffcb15; font-weight: 500;">{{ $t('setup.wallet_entry_form.sign_in') }}</a>
        </span>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import MobileDetection from "@/util/MobileDetection";
import Swal from "sweetalert2";
import trackGtagEvent from "@/util/TrackGtagEvent";
import helper from "@/util/OnboardingHelper";
import {recaptchaMixin} from "@/components/captcha/recaptcha-mixin";

export default {
  name: 'SetupWalletEntry',
  mixins: [MobileDetection, trackGtagEvent, helper, recaptchaMixin],
  props: {
    rules: Object,
    form: Object,
    step: Object,
    stepNumber: Number,
    dialog: Object
  },
  data() {
    return {
      walletLoading: false
    }
  },
  computed: {
    getTNCLink() {
      return process.env.VUE_APP_SETUP_TNC_LINK || '';
    },
    checkWalletValidEntry() {
      return !this.form.wallet || !this.form.tos || !this.step.valid
    },
    checkWalletValidated() {
      if (this.step && this.step.valid) {
        if (this.form && this.form.wallet) {
          if (this.form.tos) {
            return true;
          }
        }
      }

      return false;
    }
  },
  methods: {
    trimInput(ev) {
      ev === '' ? document.getElementById('walletIn').value = '' : null;
    },

    async next() {
      if (!this.checkWalletValidated) {
        return;
      }
      this.trackSignUpPageEvent("entered wallet number")
      let payload = {wallet: this.form.wallet};
      if (this.$route.query.referrerWallet) {
        payload.referrerWallet = this.$route.query.referrerWallet;
      }
      payload.recaptchaDetails = this.recaptcha;
      await this.loadAndPrepareRecaptchaDetails();
      this.toggleWalletLoading(true);
      try {
        await this.$cppClient.post('auto-onboard/send-otp', payload);
        this.changeStep(this.stepNumber);
        this.trackSignUpPageEvent("OTP sent")
      } catch (ex) {
        let {response} = ex;
        let {data} = response;
        if (data.internalCode === 'RECAPTCHA_V3_LOW_SCORE' || data.internalCode === 'RECAPTCHA_IS_REQUIRED') {
          await this.$feedback.showFailed(response);
          await this.startCaptchaV2Flow();
        } else {
          this.resetRecaptcha();
          await this.handleSendOtpError(response);
        }
      }
      this.toggleWalletLoading(false);
    },

    fail() {
      this.dialog.enable = true;
      this.dialog.header = "ভুল মার্চেন্ট একাউন্ট নাম্বার !";
      this.dialog.image = "error-img.svg";
      this.dialog.text = "অনুগ্রহ করে সঠিক বিকাশ মার্চেন্ট একাউন্ট নাম্বার দিন অথবা মার্চেন্ট একাউন্ট খুলতে \n" +
          "হোম পেজ এর একাউন্ট খোলার লিংকটি ব্যবহার করুন";
    },
    toggleWalletLoading(isWalletLoading) {
      this.walletLoading = !!isWalletLoading;
    },
    async handleSendOtpError(response) {
      this.$logger.loggable(response).log();
      let res = response.data;
      if (res.internalCode === 'EGV50113') {
        await this.handleSwalEvent(response);
      } else {
        await this.$feedback.showAutoFailed(response);
      }
      this.trackSignUpPageErrorEvent("Wallet entry failed: " + JSON.stringify(response?.data?.internalCode),
          response)
    },

    async handleSwalEvent(response) {
      let registerText = this.$i18n.t('landing.register');
      let homeText = this.$i18n.t('setup.coming_soon.btn.home');
      const result = await Swal.fire({
        icon: 'warning',
        title: response?.data?.errorMessageBn,
        reverseButtons: true,
        showDenyButton: true,
        allowOutsideClick: false,
        showConfirmButton: true,
        denyButtonText: homeText,
        confirmButtonText: registerText
      });
      if (result.isConfirmed) {
        await this.$router.push({name: 'landing', params: {hereToRegister: true}})
        this.$tours['myTour'].start();
      } else {
        await this.$router.push({name: 'landing'})
      }
    }
  },
  mounted() {
    this.trackSignUpPageEvent("landed on wallet number entry page");
  }
}
</script>
<style scoped>
.wallet_input p {
  margin-bottom: 26px;
  font-size: 20px
}

.wallet_input .inputNumber .v-input .v-input__slot {
  padding: 10px;
  margin-bottom: 0;
}

.tos-check .v-label {
  font-size: 20px !important;
  margin-left: 5px;
}

.tos-check .v-label a {
  color: #e2136e;
  font-weight: bold;
  text-decoration: none;
}

.setup-btn.v-btn.v-btn--disabled {
  color: #fff !important;
}

.setup-btn {
  padding: 0 6px !important;
  width: 402px;
  font-size: 23px;
  height: 56px !important;
  margin-top: 10px;
  margin-bottom: 25px !important;
}

.setup-btn:after {
  /* background-image: url('~@/assets/image/arrow.png'); */
  background-size: 21px 18px;
  box-shadow: none;
  display: inline-block;
  width: 21px;
  font-size: 23px;
  height: 56px;
  content: "";
}

.clr-default {
  background-color: #e2136e !important;
}

.terms-and-conditions {
  font-size: 18px;
}

.inputNumber-container {
  width: 401px;
  height: 56px;
  margin-bottom: 27px;
}

.bottom-space {
  margin-bottom: 5px;
}

.wallet-font {
  font-weight: 500;
}

.alert-container {
  font-size: 18px;
  color: #95989a;
}

@media screen and (max-width: 768px) {
  .terms-and-conditions {
    font-size: 13px;
  }

  .setup-btn {
    font-size: 22px;
    height: 42px !important;
    width: 294px !important;
  }
}

.recaptcha-v2-container {
  /*width: 329px !important;*/
  /*margin: auto;*/
  margin-bottom: 12px;
}

@media screen and (max-width: 425px) {
  .recaptcha-v2-container {
    width: 250px !important;
    transform: scale(0.8);
    transform-origin: 0 0;
  }
}




</style>
