const RECAPTCHA_ENGINE_TIMEOUT_MILLIS = 3000;

export const recaptchaMixin = {
  data() {
    return {
      recaptcha: {
        modality: "v3", // can be "v2" (challenge) or "v3" (seamless) or "error". The default "v3"
        v3Token: null,
        v2Challenge: null,
        v2UserResponse: null,
        clientOriginatedError: null
      },
      lastRenderedRecaptchaId: null
    }
  },

  methods: {
    resetRecaptcha() {
      if (this.recaptcha.modality === "v2") {
        try {
          window.grecaptcha.reset();
        } catch (ex) {
          console.warn(ex)
        }
      }
      this.recaptcha = {
        modality: "v3", // can be "v2" (challenge) or "v3" (seamless) or "error". The default "v3"
        v3Token: null,
        v2Challenge: null,
        v2UserResponse: null,
        clientOriginatedError: null
      }
    },

    async ensureCaptchaEngineIsReady() {
      await new Promise((accept, reject) => {
        let resolved = false;
        window.grecaptcha.ready(function () {
          if (!resolved) {
            resolved = true;
            accept();
          }
        });
        setTimeout(() => {
          if (!resolved) {
            resolved = true;
            reject(new Error("RECAPTCHA_ENGINE_TIMEOUT"))
          }
        }, RECAPTCHA_ENGINE_TIMEOUT_MILLIS);
      });
      return true;
    },

    async loadRecaptchaV3Token() {
      try {
        await this.ensureCaptchaEngineIsReady();
        this.recaptcha.v3Token = await window.grecaptcha.execute(process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY, {action: 'submit'})
      } catch (ex) {
        this.recaptcha.modality = "error";
        this.recaptcha.clientOriginatedError = String(ex.message);
      }
    },

    async startCaptchaV2Flow() {
      this.recaptcha.modality = "v2";

      try {
        this.lastRenderedRecaptchaId = window.grecaptcha.render(this.$refs.recaptchaV2Container, {
          'sitekey': process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY_V2
        });
        console.log("TOKEN", this.lastRenderedRecaptchaId)
      } catch (ex) {
        if (String(ex.message).includes("reCAPTCHA has already been rendered in this element")) {
          // pass
        } else {
          this.recaptcha.modality = "error";
          this.recaptcha.clientOriginatedError = String(ex.message);
        }
      }
    },

    async loadRecaptchaV2Details() {
      try {
        let v2UserResponse = window.grecaptcha.getResponse();
        console.debug({v2UserResponse})
        this.recaptcha.v2UserResponse = v2UserResponse;
      } catch (ex) {
        this.recaptcha.modality = "error";
        this.recaptcha.clientOriginatedError = String(ex.message);
      }
    },

    async loadAndPrepareRecaptchaDetails() {
      if (this.recaptcha.modality === "v3") {
        await this.loadRecaptchaV3Token();
      } else if (this.recaptcha.modality === "v2") {
        await this.loadRecaptchaV2Details();
      }
    },

    async closeCaptchaV2Native() {
      if (this.recaptcha.modality === "v2") {
        try {
          window.grecaptcha.reset();
          window.grecaptcha.reset(this.lastRenderedRecaptchaId);
        } catch (ex) {
          console.warn(ex)
        }
      }
    },

    async forceCloseCaptchaV2() {
      await this.closeCaptchaV2Native();
      try {
      document.querySelector(".recaptcha-v2-container").innerHTML = "";
      } catch (ex) {
        console.warn(ex)
      }
    }

  }
}