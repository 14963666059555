<template>
  <v-layout>
    <v-flex>
			<div class="email-form">
				<p class="step_card_p">{{$t('setup.confirm_email_entry_form.insert_mail')}}</p>
				<v-text-field
						v-model="form.confirmEmail"
						placeholder="name@company.com"
						outlined
						type="text"
						oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
						maxlength="100"
						dense
						class="inputNumber email-container"
						:rules="rules.emailRules"
						required
						height="56px"
						autocomplete="off"
				></v-text-field>
				<v-btn class="setup-btn clr-default" color="primary"
								:disabled="disableEmailIfInvalid" v-on:click="next"
								:loading="emailLoading"
				>{{$t('setup.proceed_btn')}}
				</v-btn>
			</div>
    </v-flex>
  </v-layout>
</template>

<script>
import trackGtagEvent from "@/util/TrackGtagEvent";
import helper from "@/util/OnboardingHelper";

export default {
  name: 'SetupEmailEntry',
  props: {
    rules: Object,
    form: Object,
    step: Object,
    stepNumber: Number,
    dialog: Object
  },
  data() {
    return {
      emailLoading: false
    }
  },
  mixins: [trackGtagEvent, helper],
  computed: {
    disableEmailIfInvalid() {
      return !this.form.confirmEmail || !this.step.valid;
    },
    isEmailStepIsValid() {
      return this.isEmailStepValid();
    }
  },
  methods: {
    async next() {
      this.trackSignUpPageEvent("confirm email address entered");
      if (!this.isEmailStepIsValid) {
        return;
      }
      this.toggleEmailLoading(true);
      try {
        await this.$cppClient.post('auto-onboard/send-email', {
          email: this.form.email,
          wallet: this.form.wallet,
        }, null, true);
        this.handleSendEmailSuccess();
      } catch ({response}) {
        await this.handleSendEmailError(response)
      }
      this.toggleEmailLoading(false)
    },

    toggleEmailLoading(isEmailLoading) {
      this.emailLoading = !!isEmailLoading;
    },
    handleSendEmailSuccess() {
      this.trackSignUpPageEvent("email sent successfully");
      this.changeStep(this.stepNumber);
    },
    async handleSendEmailError(response) {
      await this.showAndLogError(response);
      this.trackSignUpPageErrorEvent("email send failed: ", response)
    }
  },
  mounted() {
    this.trackSignUpPageEvent("landed on confirm email entry page");
  }
}
</script>
<style scoped>
.otp_input p {
  margin-bottom: 26px;
  font-size: 20px
}

.otp_input .inputNumber .v-input .v-input__slot {
  padding: 10px;
  margin-bottom: 0;
}

.v-input--selection-controls {
  margin-top: 5px;
}

.tos-check .v-label {
  font-size: 20px !important;
  margin-left: 5px;
}

.tos-check .v-label a {
  color: #e2136e;
  font-weight: bold;
  text-decoration: none;
}

.setup-btn.v-btn.v-btn--disabled {
  color: #fff !important;
}

.setup-btn {
  padding: 0 6px !important;
  width: 402px;
  font-size: 23px;
  height: 56px !important;
  letter-spacing: 0;
}

.setup-btn:after {
  /* background-image: url('~@/assets/image/arrow.png'); */
  background-size: 21px 18px;
  box-shadow: none;
  display: inline-block;
  width: 21px;
  font-size: 23px;
  height: 56px;
  content: "";
}

.clr-default {
  background-color: #e2136e !important;
}

@media screen and (max-width: 425px) {
  .email-form {
    width: 294px;
  }
}
</style>
