<template>
  <v-container class="container-setup">
    <div class="left_part">
      <v-img class="setup_left_banner" :src="bannerImg"/>
    </div>

    <div class="right_part">
      <v-sheet class="container-right">
        <img src="../../../../assets/image/bKash-logo.svg" class="bkash-icon"><br>
        <div class="title-block">
          <div v-if="!isEmailSuccess" class="title-div title-gap">
            <span class="title_style">
              {{ $t('setup.verify_email.verify_title') }}
            </span>
          </div>
          <div v-if="isEmailSuccess" class="title-div">
            <span class="title_style">
              {{ $t('setup.verify_email.success_title_1') }}
            </span>
          </div>
          <div v-if="isEmailSuccess" class="bottom-space">
            <span class="title_style container-right__title2"  style="">
              {{$t('setup.verify_email.success_title_2')}}
            </span>
          </div>
        </div>
        <br>
        <div class="container-right__email_success_msg_1">
          <span v-if="isEmailSuccess" class="sub-title_style">
            {{ $t('setup.verify_email.success_msg_1') }}
          </span>
          <span v-if="!isEmailSuccess" class="sub-title_style">
            <i18n path="setup.verify_email.sent">
              <template v-slot:email-address>
                <span class="email-font" >{{ email }}</span>
              </template>
            </i18n>
          </span>
        </div><br>
        <div class="container-right__email_success_msg_2" >
          <span class="sub-title_style">
            {{isEmailSuccess ? $t('setup.verify_email.success_msg_2') : $t('setup.verify_email.use_link')}}
            <v-btn v-if="!isEmailSuccess" depressed class="resend_email_btn" v-on:click="primaryBtnAction" :disabled="!enableResend"
                  :loading="btnLoading">
              <span>
                {{$t('setup.verify_email.resend')}}
                <v-progress-linear v-if="enableTimer" :buffer-value="progressVal" color="grey"
                                  reverse></v-progress-linear>
              </span>
              <v-chip class="mr-2 timer-border" small label outlined v-if="enableTimer"
                      text-color="#e2136e"
                      color="teal"
                     >
                {{setValueWithZero(minutes) + ':' + setValueWithZero(seconds)}}
              </v-chip>
            </v-btn>
            <small v-if="enableMaxedOutMessage" class="alert-message">
              <v-icon color="#e2136e" small>mdi-alert-octagon-outline</v-icon>
              {{ showMaxed }}
            </small>
          </span>
        </div>
        <br>
        <v-btn v-if='!(action === "EmailSuccess")'  class="home-btn" color="primary" :to="'/'">{{$t('setup.home_btn')}}</v-btn>
        <div v-if='action === "EmailSuccess"' class="fb-group group-responsive-layout fb-group__bottom-space" :data-href="facebookGroupLink"
            data-width="250"
            data-show-metadata="false">
          <blockquote :cite="facebookGroupLink"
                      class="fb-xfbml-parse-ignore">bKash Online Biz: Pilot User Group
          </blockquote>
        </div>
        <br>
        <div class="fb-group-text">
          <span>
            {{ $t("landing.facebookGroup.t1") }}<a :href="facebookGroupLink" target="_blank">
            <b style="color: #2699fb; margin-left: 5px;">{{ $t("landing.facebookGroup.t2") }}</b>
            </a> {{ $t("landing.facebookGroup.t3") }}<br>
            {{ $t("landing.facebookGroup.t4") }}
          </span>
        </div>
      </v-sheet>
    </div>
  </v-container>
</template>
<script>
import moment from "moment";
import trackGtagEvent from "@/util/TrackGtagEvent";

export default {
  name: 'CommonPage',
  mixins:[trackGtagEvent],
  props: {
    action: String,
    data: Object,
    bannerImage: String,
    title: String,
    subTitle: String,
    actionButtonTitle: String
  },
  data() {
    return {
      bannerImg: require('@/assets/image/setup_left.png'),
      btnLoading: false,
      primaryDisable: false,
      email: '',
      enableResend: true,
      timerInstance: null,
      date: moment(process.env.VUE_APP_EMAIL_RETRY_INTERVAL * 1000), // in seconds
      progressVal: 100,
      max_retry: parseInt(process.env.VUE_APP_EMAIL_MAX_TRY),
      retried: 0,
      now: Math.trunc((new Date()).getTime() / 1000)
    }
  },
  computed: {
    showMaxed() {
      if (this.date === 'maxed')
        return 'সর্বোচ্চ বার চেষ্টা করা হয়েছে';
      return '';
    },
    enableTimer() {
      return this.date !== 'maxed' && !this.enableResend;
    },
    enableMaxedOutMessage() {
      return this.date === 'maxed';
    },
    dateInMilliseconds() {
      return Math.trunc(Date.parse(this.date) / 1000)
    },
    seconds() {
      return (this.dateInMilliseconds - this.now) % 60;
    },
    minutes() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60) % 60;
    },
    hours() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60) % 24;
    },
    days() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60 / 24);
    },
    isEmailSuccess() {
      return this.action === 'EmailSuccess';
    },
    facebookGroupLink() {
      return process.env.VUE_APP_FACEBOOK_GROUP_URL || '';
    },
  },
  async mounted() {
    try {
      await this.$loadScript(process.env.VUE_APP_FACEBOOK_SCRIPT_URL);
      this.$nextTick(() => {
        window.FB.XFBML.parse();
      });
    } catch (error) {
      console.log("SCRIPT LOADING ERROR");
    }

    if (this.action === 'EmailVerify') {
      this.handleEmailVerification();
    } else if (this.action === 'EmailSuccess') {
      this.trackSignUpPageEvent("landed on congratulations page");
    }
  },
  methods: {
    addPathToLocation() {
      history.pushState(
        {},
        null,
        this.$route.path + '/verification-email-sent'
      )
    },
    async primaryBtnAction() {
      if (this.action === 'EmailVerify') {
        await this.handleEmailVerificationBtnAction();
      } else if (this.action === 'EmailSuccess') {
        this.$emit('change-fragment', 'Setup', null, null);
        await this.$router.push({name: 'sign-up'});
      }
    },

    disableResend() {
      this.date = this.getFutureDate();
      this.now = Math.trunc((new Date()).getTime() / 1000);
      this.enableResend = false;

      if (this.retried === this.max_retry) {
        this.resetTimer(true);
      } else {
        this.startTimer();
      }
    },

    resetTimer(maxed) {
      this.date = maxed ? 'maxed' : null;
      this.progressVal = 100;
      clearInterval(this.timerInstance);
    },
    getFutureDate() {
      let dt = new Date();
      dt.setSeconds(dt.getSeconds() + parseInt(process.env.VUE_APP_EMAIL_RETRY_INTERVAL));
      return dt;
    },
    setValueWithZero(value) {
      if (value < 0) {
        return '00';
      }
      if (value.toString().length <= 1) {
        return `0${value}`;
      }
      return value;
    },
    handleEmailVerification(){
      this.email = this.data?.form?.email ?? "N/A";
      this.primaryDisable = true;
      this.addPathToLocation();
      this.trackSignUpPageEvent("landed on email verify page")
      this.disableResend();
    },
    toggleBtnLoading(isBtnLoading){
      this.btnLoading = !!isBtnLoading;
    },

    handleEmailResendSuccess(){
      this.trackSignUpPageEvent("email resent");
      this.$feedback.showAutoSuccessMessage("আপনার ইমেল চেক করুন, ইমেল আবার প্রেরণ করা হয়েছে!");
      this.disableResend();
    },

    handleEmailResendError(response){
      this.$logger.loggable(response).log();
      this.$feedback.showAutoFailed(response, () => {
        this.retried = this.max_retry;
        this.disableResend();
      });
      this.trackOnboardingErrorEvent("email resend failed");
    },

    async handleEmailVerificationBtnAction(){
      const confirmed = await this.$feedback.getConfirmation();
      if (confirmed) {
        this.retried++;
        this.toggleBtnLoading(true);
        try {
          await this.$cppClient.post('auto-onboard/resend-email', {
            wallet: this.data?.form?.wallet
          });
          this.handleEmailResendSuccess();
        } catch ({response}) {
          this.handleEmailResendError(response);
        }
        this.toggleBtnLoading(false);
      }
    },

    startTimer(){
      this.timerInstance = setInterval(() => {
        if ((this.days + this.hours + this.minutes + this.seconds) === 0) {
          this.enableResend = true;
          this.resetTimer();
        } else {
          this.now = Math.trunc((new Date()).getTime() / 1000);
          this.progressVal = this.progressVal - (100 / process.env.VUE_APP_EMAIL_RETRY_INTERVAL);
        }
      }, 1000);
    }
  }
}
</script>

<style scoped>
.setup_left_banner {
  max-width: 855px !important;
  margin-bottom: 58px;
  margin-top: 25px;
}
.title-block {
  margin-bottom: 30px;
  display: inline-grid;
}

.title-div {
  display: inline-flex;
  max-width: 500px;
}

.title-gap {
  margin-top: 150px;
}

.title_style {
  font-family: Roboto;
  font-size: 43px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #95989a;
}

.sub-title_style {
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #95989a;
  max-width: 850px;
}

.left_part {
  padding: 132px 52px;
  flex: 1;
  width: 50%;
  background: rgba(149, 152, 154, 0.25)
}

.right_part {
  flex: 2;
  width: 50%;
}

.resend_email_btn.v-btn {
  font-size: 18px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffcb15 !important;
  padding: 0 !important;
  height: auto;
  background-color: transparent !important;
}

.resend_email_btn.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: transparent !important;
}

.home-btn {
  padding: 0 6px !important;
  width: 289px;
  font-size: 23px;
  height: 56px !important;
  margin-top: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  margin-bottom: 54px;
}

.fb-group-text {
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
  max-width: 570px;
  display: inline-flex;
}

.bottom-space{
  margin-bottom: 8px;
}

.container-right__title2 {
  color: #e2136e;
  font-weight: bold;
}

.container-right__email_success_msg_1 {
  display: inline-flex;
  margin-bottom: 15px;
}

.email-font{
  font-weight: 500;
}

.container-right__email_success_msg_2 {
  display: inline-flex;
  margin-bottom: 30px;
}

.timer-border {
  border: none;
}

.alert-message{
  display: block;
  color: #e2136e;
  line-height: 10px;
}

.fb-group__bottom-space {
  margin-bottom: 20px;
}

@media screen and (max-width: 1400px) {
  .title_style {
    font-size: 30px;
  }

  .title-div {
    width: 350px;
  }

  .sub-title_style {
    font-size: 18px;
  }

  .fb-group-text {
    font-size: 20px;
  }
}


@media screen and (max-width: 425px) {
  .left_part {
    display: none;
  }

  .title_style {
    font-size: 25px;
  }

  .title-div {
    width: 288px;
  }

  .title-gap {
    margin-top: 60px;
  }

  .title-block {
    margin-bottom: 0;
  }

  .sub-title_style {
    font-size: 16px;
  }

  .home-btn {
    width: 243px;
    height: 47px !important;
    font-size: 19px;
  }

  .fb-group-text {
    font-size: 15px;
  }

  .bkash-icon {
    width: 140px;
    height: 64px;
  }
}

</style>
