<template>

  <v-layout column fill-height class="coming-soon-container">
    <v-container>
      <v-row justify="center">
        <v-col>
          <v-img
              max-height="234px"
              max-width="274px"
              :src="bannerImg"
              alt="Coming Soon"
              class="coming_soon_banner img-container"
          ></v-img>
        </v-col>
      </v-row>

      <v-row class="call-to-action-titles">
        <v-col>
          <h2>{{  $t('setup.coming_soon.title') }}</h2>
          <p>{{  $t('setup.coming_soon.sub') }}</p>
        </v-col>
      </v-row>

      <v-row class="call-to-action-buttons">
        <v-col>
          <v-btn href="#" class="right-space">{{ $t('setup.coming_soon.btn.register') }}</v-btn>
          <v-btn href="/">{{ $t('setup.coming_soon.btn.home') }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>

</template>
<script>
export default {
  name: 'ComingSoon',
  data() {
    return {
      bannerImg: require('@/assets/image/comingsoonbanner.png')
    }
  }
}
</script>

<style scoped>
.coming-soon-container {
  background: url("../../../../assets/image/coming-soon-bg.png") 16% 42%;
}

.call-to-action-titles,
.call-to-action-buttons {
  text-align: center;
}

.call-to-action-titles h2 {
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #707070;
  margin-bottom: 12px;
}

.call-to-action-titles p {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  line-height: 32px;
  color: #707070;
  margin-bottom: 50px;
}

.call-to-action-buttons button:first-child {
  margin-right: 23px;
}

.call-to-action-buttons .v-btn {
  background: #e2136e !important;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
}

.img-container {
  margin: 60px auto 46px
}

.right-space {
  margin-right: 23px
}

/*
  For Mobile View
*/
@media screen and (max-width: 768px) {
  .coming-soon-container {
    background: url("../../../../assets/image/coming-soon-mobile-bg.svg") 11% 55%;
  }
  .coming_soon_banner {
    max-width: 90px;
    max-height: 91px;
  }
  .coming_soon_banner {
    max-width: 102px !important;
    max-height: 103px !important;
    margin: 88px auto 0 !important;

  }

  .call-to-action-titles h2 {
    font-size: 20px;
  }
  .call-to-action-titles p {
    font-size: 14px;
    line-height: 16px;
  }

  .call-to-action-buttons .v-btn {
    display: block;
    width: 100%;
    margin-bottom: 17px;
    padding: 12px 10px;
    line-height: 20px;
    height: 45px;
  }
}
</style>