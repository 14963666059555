<template>
  <!--  Modal on error  -->
  <div>
    <v-dialog
        v-model="dialog.enable"
        max-width="568"
    >
      <v-card class="justify-center modal_card">
        <v-card-title class="headline justify-center">
          <slot name="header"></slot>
        </v-card-title>

        <v-card-text class="error-img justify-center" style="display: inline-grid">
          <slot name="image" class="error-img"></slot>
        </v-card-text>

        <v-card-text class="para justify-center">
          <slot name="content">
          </slot>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-spacer></v-spacer>

          <v-btn
              color="green darken-1"
              class="btn btn-bKash"
              text
              @click="dialog.enable = false"
          >
            ঠিক আছে
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: Object,
  }
}
</script>

<style>
.modal_card {
  padding: 35px;
  letter-spacing: 0;
}
.headline {
  font-size: 18px;
}
.para {
  font-size: 14px;
}
.error-img .v-image{
  max-width: 84px !important;
}
.justify-center {
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}
.btn {
  padding: 5px 10px;
  font-size: 16px;
  min-width: 150px !important;
  letter-spacing: 0;
}
.btn-bKash {
  background-color: #e2136e;
  color: #fff !important;
}

@media screen and (max-width: 768px) {
  .modal_card {
    padding: 15px 0;
  }
}
</style>