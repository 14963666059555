<template>
  <v-layout>
    <v-flex>
      <p class="step_card_p">{{$t('setup.otp_entry_form.insert_otp')}}</p>
      <v-text-field
          v-model="form.otp"
          placeholder="XXXXXX"
          outlined
          type="number"
          id="otpIn"
          v-on:input="trimInput($event)"
          oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          maxlength="6"
          dense
          class="inputNumber otp-container"
          :rules="rules.otpRules"
          required
          height= '56px'
      ></v-text-field>

      <p class="left-align">
        <v-btn depressed class="resend_otp_btn" @click="resendOTP" :disabled="!enableResend || otpLoading"
               :loading="otpResendLoading">
          <span>
            {{$t('setup.otp_entry_form.resend')}}
            <v-progress-linear v-if="enableTimer" :buffer-value="progressVal" color="grey"
                               reverse></v-progress-linear>
          </span>
          <v-chip class="mr-2 remove-border" small label outlined v-if="enableTimer"
                  text-color="#e2136e"
                  color="teal"
                  >
            {{setValueWithZero(minutes) + ':' + setValueWithZero(seconds)}}
          </v-chip>
        </v-btn>
        <small v-if="enableMaxedOutMessage" class="alert-container">
          <v-icon color="#e2136e" small>mdi-alert-octagon-outline</v-icon>
          {{ showMaxed }}
        </small>
      </p>
      <v-btn class="setup-btn clr-default" color="primary" v-on:click="next"
             :disabled="disableOtpIfInvalid" :loading="otpLoading">{{$t('setup.proceed_btn')}}
      </v-btn>

    </v-flex>
  </v-layout>
</template>

<script>
import MobileDetection from "@/util/MobileDetection";
import trackGtagEvent from "@/util/TrackGtagEvent";
import helper from "@/util/OnboardingHelper";

export default {
  name: 'SetupOtpEntryPage',
  mixins: [MobileDetection, trackGtagEvent, helper],
  props: {
    rules: Object,
    form: Object,
    step: Object,
    stepNumber: Number,
    dialog: Object
  },
  data() {
    return {
      enableResend: false,
      otpLoading: false,
      otpResendLoading: false,
      timerInstance: null,
      date: null, // in seconds
      progressVal: 100,
      max_retry: parseInt(process.env.VUE_APP_OTP_MAX_TRY),
      retried: 0,
      now: Math.trunc((new Date()).getTime() / 1000)
    }
  },
  computed: {
    disableOtpIfInvalid() {
      return !this.form.otp || !this.step.valid || this.otpResendLoading;
    },
    isOtpStepIsValid() {
      if (this.step && this.step.valid)
        if (this.form && this.form.otp)
          return true;

      return false;
    },
    showMaxed() {
      if (this.date === 'maxed')
        return 'সর্বোচ্চ বার চেষ্টা করা হয়েছে';
      return '';
    },
    enableTimer() {
      return this.date !== 'maxed' && !this.enableResend;
    },
    enableMaxedOutMessage() {
      return this.date === 'maxed';
    },
    dateInMilliseconds() {
      return Math.trunc(Date.parse(this.date) / 1000)
    },
    seconds() {
      return (this.dateInMilliseconds - this.now) % 60;
    },
    minutes() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60) % 60;
    },
    hours() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60) % 24;
    },
    days() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60 / 24);
    }
  },
  methods: {
    trimInput(ev) {
      ev === '' ? document.getElementById('otpIn').value = '' : null;
    },
    async next() {
      if (!this.isOtpStepIsValid) {
        return;
      }
      this.trackSignUpPageEvent("entered OTP");
      this.toggleOtpLoading(true);
      try {
        await this.$cppClient.post('auto-onboard/verify-otp',
            {otp: this.form.otp, wallet: this.form.wallet});
        this.changeStep(this.stepNumber);
        this.trackSignUpPageEvent("successfully verified OTP");
      } catch ({response}) {
        await this.showAndLogError(response);
        this.trackSignUpPageErrorEvent("OTP verify failed: " + JSON.stringify(response?.data?.internalCode),
            response);
      }
      this.toggleOtpLoading(false);
    },
    handleOnChange() {
      this.step.valid = false;
    },
    handleOnComplete(e) {
      if (e.length === 6) {
        this.form.otp = e;
        this.step.valid = true;
      }
    },
    async resendOTP() {
      this.trackSignUpPageEvent("resend OTP button clicked");
      this.retried++;
      this.form.otp = null;
      this.toggleOtpResendLoading(true);
      try {
        await this.$cppClient.post('auto-onboard/resend-otp', {wallet: this.form.wallet});
        this.handleResendOtpSuccess();
      } catch ({response}) {
        await this.showAndLogError(response);
        this.trackSignUpPageErrorEvent("OTP resend failed: " + JSON.stringify(response?.data?.internalCode),
            response)
      }
      this.toggleOtpResendLoading(false);
    },
    disableResend() {
      this.date = this.getFutureDate();
      this.now = Math.trunc((new Date()).getTime() / 1000);
      this.toggleEnableResend(false);
      if (this.retried === this.max_retry) {
        this.resetTimer(true)
      } else {
        this.startTimer();
      }
    },
    resetTimer(maxed) {
      this.date = maxed ? 'maxed' : null;
      this.progressVal = 100;
      clearInterval(this.timerInstance);
    },
    getFutureDate() {
      let dt = new Date();
      dt.setSeconds(dt.getSeconds() + parseInt(process.env.VUE_APP_OTP_RETRY_INTERVAL));
      return dt;
    },
    setValueWithZero(value) {
      if (value < 0) {
        return '00';
      }
      if (value.toString().length <= 1) {
        return `0${value}`;
      }
      return value;
    },
    toggleOtpLoading(isOtpLoading) {
      this.otpLoading = !!isOtpLoading;
    },
    toggleOtpResendLoading(isOtpResendLoading) {
      this.otpResendLoading = !!isOtpResendLoading;
    },
    toggleEnableResend(isEnableResend) {
      this.enableResend = !!isEnableResend;
    },
    startTimer(){
      this.timerInstance = setInterval(() => {
        if ((this.days + this.hours + this.minutes + this.seconds) === 0) {
          this.toggleEnableResend(true);
          this.resetTimer();
        } else {
          this.now = Math.trunc((new Date()).getTime() / 1000);
          this.progressVal = this.progressVal - (100 / process.env.VUE_APP_OTP_RETRY_INTERVAL);
        }
      }, 1000);
    },
    handleResendOtpSuccess() {
      this.disableResend();
      this.$feedback.showAutoSuccessMessage("দয়া করে আপনার ফোনটি পরীক্ষা করুন, ওটিপি পুনরায় পাঠানো হয়েছে!");
      this.trackSignUpPageEvent("OTP resend successful");
    }

  },
  mounted() {
    this.disableResend();
    this.trackSignUpPageEvent("landed on OTP entry page");
  }
}
</script>
<style scoped>
.otp_input p {
  margin-bottom: 26px;
  font-size: 20px
}

.resend_otp_btn.v-btn {
  font-size: 18px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffcb15!important;
  padding: 0 !important;
  height: auto;
}

.resend_otp_btn.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: transparent !important;
}

.otp_input .inputNumber .v-input .v-input__slot {
  padding: 10px;
  margin-bottom: 0;
}

.v-input--selection-controls {
  margin-top: 5px;
}

.tos-check .v-label {
  font-size: 20px !important;
  margin-left: 5px;
}

.tos-check .v-label a {
  color: #e2136e;
  font-weight: bold;
  text-decoration: none;
}

.setup-btn.v-btn.v-btn--disabled {
  color: #fff !important;
}

.setup-btn {
  padding: 0 6px !important;
  width: 402px;
  font-size: 23px;
  height: 56px !important;
  letter-spacing: 0;
}

.setup-btn:after {
  /* background-image: url('~@/assets/image/arrow.png'); */
  background-size: 21px 18px;
  box-shadow: none;
  display: inline-block;
  width: 21px;
  font-size: 23px;
  height: 56px;
  content: "";
}

.clr-default {
  background-color: #e2136e !important;
}

.otp-input {
  max-width: 49px;
  width: 100%;
  height: 40px;
  padding: 5px;
  margin: 0 4px 0 0;
  font-size: 20px;
  border-radius: 2px;
  border: solid 1px #f3f3f3;
  background-color: #fff;
  text-align: center;
}

.otp-input.error {
  border: 1px solid red !important;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-container {
  width: 401px;
  margin-bottom: 15px;
}

.left-align {
  text-align: left;
}

.remove-border {
  border: none;
}

.alert-container {
  display: block;
  color: #e2136e;
  line-height: 10px;
}

@media screen and (max-width: 768px) {
  .resend_otp_btn {
    font-size: 12px !important;
    margin: 0;
  }
}
</style>
